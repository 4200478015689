@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;600;800&display=swap');

html, body {
  font-family: 'Montserrat', sans-serif;
}

@import '../bootstrap/functions';

// Bootstrap overrides START HERE

$primary: rgb(54, 164, 221);

// Bootstrap overrides ENDS HERE
@import '../bootstrap/variables';

$btn-padding-y: map-get($spacers, 3);
$btn-padding-x: map-get($spacers, 4);

@import '../bootstrap/mixins';

// Custom components overrides START HERE
$footer-bg-color: rgb(46, 45, 44);
$footer-color: rgb(235, 241, 251);

.navbar-theme-colors {
  @extend .navbar-dark;
  @extend .bg-primary;
}

.btn {


  //padding-left: map-get($spacers, 3);
  //padding-right: map-get($spacers, 3);
  //
  //padding-top: map-get($spacers, 4);
  //padding-bottom: map-get($spacers, 4);

  //padding: map-get($spacers, 4) map-get($spacers, 3);

  text-transform: uppercase;
  font-weight: 600 !important;
}

// Custom components overrides ENDS HERE
@import '../core';

#carouselHome {
  margin-top: -50px;
}

:root {
  font-size: 14px;
}